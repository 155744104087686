<template>
  <div>
    <div v-if="currentPage !== undefined && error === undefined">
      <div :key="currentPage.id">
        <EngageWalkthrough
          v-if="
            currentPage.meta.type === 'engage_virtualrooms.WalkthroughRoom'
          "
          :pageData="currentPage"
          :pageChildren="currentChildren"
          :accessibilityStream="accessibilityStream"
          @showLanding="showLanding(currentPage.id, $event)"
          @closeAccessibility="accessibilityStream = false"
        />
        <EngagementRoom
          v-if="currentPage.meta.type === 'engage_virtualrooms.VirtualRoomPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
          :accessibilityStream="accessibilityStream"
          @showLanding="showLanding(currentPage.id, $event)"
          @closeAccessibility="accessibilityStream = false"
        />
        <Page
          v-if="currentPage.meta.type === 'wagtailcore.Page'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <WebPageLegacy
          v-if="currentPage.meta.type === 'engage_pages.WebPageLegacy'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <WebPage
          v-if="currentPage.meta.type === 'engage_pages.WebPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <MapPage
          v-if="currentPage.meta.type === 'engage_maps.MapPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <MapVirtualTourPage
          v-if="currentPage.meta.type === 'engage_maps.MapVirtualTourPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
      </div>
      <LandingStreamfield
        :pageType="typeOfPage"
        ref="landing"
        :data="currentPage"
        :key="currentPage.id"
        v-if="showStreamfield"
        @closed="landingClosed"
      >
      </LandingStreamfield>
    </div>
    <div v-else-if="error !== undefined">
      <Error404 v-if="error.response.status === 404"></Error404>
    </div>
    <LoadingScreen v-show="loading" />
  </div>
</template>

<script>
import Vue from "vue";

import Page from "./wagtail/Page.vue";
import EngagementRoom from "./wagtail/EngagementRoom.vue";
import WebPageLegacy from "./wagtail/WebPageLegacy.vue";
import EngageWalkthrough from "./wagtail/EngageWalkthrough2.vue";
import MapPage from "./wagtail/MapPage.vue";
import WebPage from "./wagtail/WebPage.vue";
import Error404 from "./wagtail/Error404.vue";
import MapVirtualTourPage from "./wagtail/MapVirtualTourPage.vue";

import LoadingScreen from "../components/loadingScreen/LoadingScreen.vue";
import LandingStreamfield from "../components/misc/LandingStreamfield.vue";

export default {
  name: "WagtailPage",
  data: () => ({
    dialog: true,
    error: undefined,
    loading: false,
    accessibilityStream: false,
    landingStreamfieldsClosed: [],
  }),
  components: {
    Page,
    EngagementRoom,
    WebPageLegacy,
    MapPage,
    WebPage,
    Error404,
    LoadingScreen,
    LandingStreamfield,
    MapVirtualTourPage,
    EngageWalkthrough,
  },
  mounted() {
    // Load the page data for this page
    this.loadPage(this.$route.path);
    console.log(
      this.$store.state.wagtail.currentPage,
      this.showStreamfield,
      "current page data"
    );
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    currentPage() {
      return this.$store.state.wagtail.currentPage;
    },
    currentPopup() {
      return this.$store.state.wagtail.currentPopup;
    },
    currentChildren() {
      return this.$store.state.wagtail.currentChildren;
    },
    showStreamfield() {
      if (
        this.currentPage && this.currentPage.landing_streamfield !== undefined &&
        this.currentPage.landing_streamfield.length > 0
      )
        if (!this.landingStreamfieldsClosed.includes(this.currentPage.id))
          if (
            localStorage.getItem("accessibilityModeState") == "false" ||
            this.accessibilityStream
          )
            // If there is actually streamfield data
            // And if this streamfield hasn't been closed before
            return true; // Show it
      return false;
    },
    typeOfPage() {
      if (
        this.$store.state.wagtail.currentPage.meta.type == "engage_maps.MapPage"
      ) {
        return "map";
      } else if (
        this.$store.state.wagtail.currentPage.meta.type ==
        "engage_virtualrooms.VirtualRoomPage"
      ) {
        return "room";
      } else return "page";
    },
  },
  watch: {
    currentPath(newPath) {
      // If the path changes, we want to load new page data from the API
      console.log("Wagtail page path changed: ", newPath);
      if (this.currentPage.meta.type !== 'engage_virtualrooms.WalkthroughRoom') {
        this.loadPage(newPath);
      }
      // brings back scrollbars
      var bodyElement = document.querySelector("body");
      var htmlElement = document.querySelector("html");
      bodyElement.classList.remove("body-scrollhide");
      htmlElement.classList.remove("html-scrollhide");
      bodyElement.classList.remove("body-scrollhider");
      bodyElement.classList.remove("body-scrollshow");
      // console.log(this.currentPage.meta.type, "wagtail page path changed");
    },
  },
  methods: {
    showLanding(pageId, custom) {
      this.landingStreamfieldsClosed = this.landingStreamfieldsClosed.filter(
        function (item) {
          return item !== pageId;
        }
      );

      if (custom === "read-welcome") {
        this.accessibilityStream = true;
      }
    },
    landingClosed() {
      this.accessibilityStream && (this.accessibilityStream = false);
      this.landingStreamfieldsClosed.push(this.currentPage.id);
      console.log("Closed: ", this.landingStreamfieldsClosed);
      let html = document.querySelector("html");

      if (
        this.$store.state.wagtail.currentPage.meta.type == "engage_maps.MapPage"
      ) {
        // html.style.overflowY = 'scroll'
        html.style.overflowX = "hidden";
      } else if (
        this.$store.state.wagtail.currentPage.meta.type ==
        "engage_virtualrooms.VirtualRoomPage"
      ) {
        html.style.overflowY = "hidden";
        html.style.overflowX = "hidden";
      } else {
        html.style.overflowY = "auto";
        html.style.overflowX = "hidden";
      }
    },
    updatePageTitle(title) {
      Vue.nextTick(() => {
        document.title = title || "Engage";
      });
    },
    loadPage(path) {
      this.error = undefined;
      let self = this;

      if (this.currentPage !== undefined) {
        let targetPath = path;
        let currentPath = new URL(this.currentPage.meta.html_url).pathname;
        console.log({currentPath}, 'hereee');

        // Skip loading if we're just trying to load the current page
        if (targetPath === currentPath) {
          self.updatePageTitle(this.currentPage.title);
          return;
        }
        console.log("current page", this.currentPopup);
        // If current page is a virtual room, Iterate children and if we find that we're
        // requesting a child we skip dispatching a load as we already have the room loaded.
        if (
          (this.currentPage && this.currentPage.meta.type ===
            "engage_virtualrooms.VirtualRoomPage") ||
            (this.currentPopup && this.currentPopup.meta.type ===
            "engage_virtualrooms.PanoramaRoomPopup")
        ) {
          // Loop through child_content
          for (let child of this.currentPage.child_content) {
            let childPath = new URL(child.html_url).pathname;
            if (childPath === targetPath) {
              let loggedIn = self.$store.state.auth.status.loggedIn;
              // TODO: This isn't the most elegant solution, and requires supporting all Wagtail privacy types
              // If the page isn't restricted, or we're logged in, skip dispatching a load
              // Otherwise, we'll trigger a proper reload, which will force a login screen if required
              if (!child.restricted || loggedIn) {
                self.updatePageTitle(child.title);
                return; // We skip dispatching a load if we're requesting a virtual room popup
              }
            }
          }
          for (let child of this.currentPopup.child_content) {
            let childPath = new URL(child.html_url).pathname;
            if (childPath === targetPath) {
              let loggedIn = self.$store.state.auth.status.loggedIn;
              // TODO: This isn't the most elegant solution, and requires supporting all Wagtail privacy types
              // If the page isn't restricted, or we're logged in, skip dispatching a load
              // Otherwise, we'll trigger a proper reload, which will force a login screen if required
              if (!child.restricted || loggedIn) {
                self.updatePageTitle(child.title);
                return; // We skip dispatching a load if we're requesting a virtual room popup
              }
            }
          }

          // Loop through panorama_child_content
          for (let panoramaChild of this.currentPage.panorama_child_content) {
            let panoramaChildPath = new URL(panoramaChild.html_url).pathname;
            if (panoramaChildPath === targetPath) {
              let loggedIn = self.$store.state.auth.status.loggedIn;
              // TODO: This isn't the most elegant solution, and requires supporting all Wagtail privacy types
              // If the page isn't restricted, or we're logged in, skip dispatching a load
              // Otherwise, we'll trigger a proper reload, which will force a login screen if required
              if (!panoramaChild.restricted || loggedIn) {
                self.updatePageTitle(panoramaChild.title);
                return; // We skip dispatching a load if we're requesting a virtual room popup
              }
            }
          }
          for (let panoramaChild of this.currentPopup.panorama_child_content) {
            let panoramaChildPath = new URL(panoramaChild.html_url).pathname;
            if (panoramaChildPath === targetPath) {
              let loggedIn = self.$store.state.auth.status.loggedIn;
              // TODO: This isn't the most elegant solution, and requires supporting all Wagtail privacy types
              // If the page isn't restricted, or we're logged in, skip dispatching a load
              // Otherwise, we'll trigger a proper reload, which will force a login screen if required
              if (!panoramaChild.restricted || loggedIn) {
                self.updatePageTitle(panoramaChild.title);
                return; // We skip dispatching a load if we're requesting a virtual room popup
              }
            }
          }
        }
      }

      if (this.currentPage && this.currentPage.meta.type==='engage_virtualrooms.EngageWalkthrough') {
        return
      }

      // Trigger the loading screen
      this.loading = true;

      this.$store.dispatch("wagtail/getPage", path).then(
        (response) => {
          self.loading = false;
          // If we encounter a redirect page, we push a new URL to the rounter
          if (response.meta.type === "engage_pages.RedirectPage") {
            if (response.target_page !== undefined) {
              var path = new URL(response.target_page).pathname;
              self.$router.push(path);
            }
          } else if (response.meta.type === "engage_pages.GlobalRedirectPage") {
            if (response.target_page !== undefined) {
              //if engage_page meta type is GlobalRedirectPage use the location.href func instead to do a hard redirect to an external URL
              var path = response.target_page;
              window.location.href = response.target_page;
            }
          }

          self.updatePageTitle(response.title);
        },
        (error) => {
          self.loading = false;

          if (
            error.response.status === 404 &&
            self.$store.state.auth.status.loggedIn
          ) {
            self.error = error;
          } else {
            self.error = undefined;
            self.$router.push("/login?redirect=" + window.location.pathname);
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
